import styles from './index.module.less';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
const Index = ({ content }: { content: any }) => {
  const router = useRouter();
  const { t } = useTranslation();

  if (content.path == '/onlyfans-protection') {
    return (
      <div className={styles.Solution} onClick={(e) => e.stopPropagation()}>
        {content.dropdownItems.map((item: any, index: number) => (
          <div
            key={index}
            onClick={(e) => {
              router.push(item.path);
            }}
          >
            {item.text}
          </div>
        ))}
      </div>
    );
  } else if (content.path == '/content-monitoring') {
    return (
      <div className={styles.Service} onClick={(e) => e.stopPropagation()}>
        {content.dropdownItems.map((item: any, index: number) => (
          <div
            key={index}
            onClick={(e) => {
              router.push(item.path);
            }}
          >
            <div className={styles.serviceItemHead}>
              <img
                src={
                  index == 0
                    ? 'https://res-front.pumpsoul.com/prod-erasa/image/monitoring-blue.svg'
                    : 'https://res-front.pumpsoul.com/prod-erasa/image/sz-blue.svg'
                }
                alt={item.text}
              />
              <span>{item.text}</span>
            </div>
            {index == 0 && (
              <ul className={styles.serviceItemList}>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    router.push('/content-monitoring/reverse-username-search');
                  }}
                >
                  {t('Reverse Username Search')}
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    router.push('/content-monitoring/reverse-video-search');
                  }}
                >
                  {t('Reverse Video Search')}
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    router.push('/content-monitoring/reverse-photo-search');
                  }}
                >
                  {t('Reverse Photo Search')}
                </li>
                <li
                  onClick={(e) => {
                    e.stopPropagation();
                    router.push('/content-monitoring/reverse-face-search');
                  }}
                >
                  {t('Reverse Face Search')}
                </li>
              </ul>
            )}
          </div>
        ))}
      </div>
    );
  } else if (content.path == '/dmca-protection-badge') {
    return (
      <div className={styles.Resources} onClick={(e) => e.stopPropagation()}>
        <div>
          <div
            onClick={(e) => {
              router.push('/blog');
            }}
          >
            Blog
          </div>
          <div
            onClick={(e) => {
              router.push('/dmca-protection-badge');
            }}
          >
            DMCA Badges
          </div>
          <div
            onClick={(e) => {
              router.push('/guide');
            }}
          >
            {t('Guides')}
          </div>
        </div>
        <div>
          <div>{t('Free Tools')}</div>
          <div
            onClick={(e) => {
              router.push('/onlyfans-restricted-words-checker');
            }}
          >
            {t('OnlyFans Restricted Words Checker')}
          </div>
          <div
            onClick={(e) => {
              router.push('/onlyfans-caption-generator');
            }}
          >
            {t('OnlyFans Caption Generator')}
          </div>
          <div
            onClick={(e) => {
              router.push('/compare');
            }}
          >
            {t('Platform Comparison')}
          </div>
        </div>
        <div>
          <div>{t('Community')}</div>
          <div
            onClick={() => {
              window.open('https://discord.gg/vmZfzh98gz', '_blank');
            }}
          >
            <div>
              <img
                src="https://res-front.pumpsoul.com/prod-erasa/image/contactUs/discord.svg"
                alt="discord"
                loading="lazy"
              />
            </div>
            <div>
              <div>Discord</div>
              <div>{t('Receive platform operation guides and get official tips and support.')}</div>
            </div>
          </div>
          <div
            onClick={() => {
              window.open('https://x.com/erasaprotect', '_blank');
            }}
          >
            <div>
              <img src="https://res-front.pumpsoul.com/prod-erasa/image/contactUs/x.svg" alt="x" loading="lazy" />
            </div>
            <div>
              <div>Twitter</div>
              <div>{t('Stay updated with the latest news.')}</div>
            </div>
          </div>
          <div
            onClick={() => {
              window.open('https://t.me/erasaprotect', '_blank');
            }}
          >
            <div>
              <img src="https://res-front.pumpsoul.com/prod-erasa/image/contactUs/tg.svg" alt="tg" loading="lazy" />
            </div>
            <div>
              <div>Telegram</div>
              <div>{t('Get 1-on-1 support from a professional consultant.')}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Index;
