import { useEffect, useState, type ReactNode } from 'react';
import Link from 'next/link';
import { NavHamburger, NavHamburgerDash } from '@/components/Layouts';
import styles from './index.module.less';
import { AppSettings } from '@/shared/app-common';
import { useRouter } from 'next/router';
import { useUserProvider } from '@/context/UserProvider';
import { UpOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';
const MobileHeader = ({ navItems }: { navItems: any[] }) => {
  const [isExpandedString, setIsExpandedString] = useState('');
  const router = useRouter();
  const { isLogin, userInfo, onSignOut } = useUserProvider();
  const [isLgi, setisLgi] = useState(false);
  const { t } = useTranslation();
  const getTitle = (): any => {
    let pathname = router.pathname;
    let arr = [
      {
        url: 'dashboard',
        desc: 'Dashboard',
      },
      {
        url: 'leaks-found',
        desc: 'Leaks Found',
      },
      {
        url: 'safe-links',
        desc: 'Safe Links',
      },
      {
        url: 'social-media',
        desc: 'Social Media',
      },
      {
        url: 'profile',
        desc: 'Setting',
      },
      {
        url: 'protected-Accounts',
        desc: 'Protected Accounts',
      },
      {
        url: 'setting',
        desc: 'Setting',
      },
    ];
    let index = arr.findIndex((item) => pathname.includes(item.url));
    return index == -1 ? -1 : arr[index];
  };
  useEffect(() => {
    setisLgi(isLogin);
  }, [isLogin]);
  return (
    <div
      className={styles.mobileHeaderWarp}
      style={{
        backgroundColor: router.pathname.indexOf('report/') == -1 ? 'transparent' : '#ffffff',
      }}
    >
      {getTitle() == -1 ? (
        <div className={styles.navToolbar}>
          <div className={styles.navToolbarSection}>
            <NavHamburger>
              <div>
                {navItems.map((item: any, index: number) => (
                  <ul key={index} className={styles.navItem}>
                    <div
                      className={styles.first}
                      onClick={(e) => {
                        if (item.path == '/plan' || item.path == '/affiliate') {
                          router.push(item.path);
                          setIsExpandedString(item.text);
                          return;
                        }
                        e.stopPropagation();
                        if (isExpandedString == item.text) {
                          setIsExpandedString('');
                        } else {
                          setIsExpandedString(item.text);
                        }
                      }}
                    >
                      <div
                        style={{
                          color: isExpandedString == item.text ? '#428DFF' : '',
                        }}
                      >
                        {item.text}
                      </div>
                      {item.dropdownItems && (
                        <div
                          style={{
                            transition: 'all 0.3s',
                          }}
                          className={classNames({
                            [styles.down]: isExpandedString == item.text,
                          })}
                        >
                          <UpOutlined />
                        </div>
                      )}
                    </div>
                    <ul className={styles.dropdownItem}>
                      {isExpandedString == item.text &&
                        item.dropdownItems?.map((itemInner: any, indexInner: number) => (
                          <li
                            key={indexInner}
                            onClick={() => {
                              router.push(itemInner.path);
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: router.pathname == itemInner.path ? '#428DFF' : '#031640',
                              }}
                            ></span>
                            <span
                              style={{
                                color: router.pathname == itemInner.path ? '#428DFF' : '',
                              }}
                            >
                              {itemInner.text}
                            </span>
                          </li>
                        ))}
                    </ul>
                  </ul>
                ))}
                {isLogin ? (
                  <div
                    className={styles.signUp}
                    onClick={() => {
                      onSignOut();
                    }}
                  >
                    {t('Sign out')}
                  </div>
                ) : (
                  <div
                    className={styles.signUp}
                    onClick={() => {
                      router.push('/login');
                    }}
                  >
                    {t('Sign up')}
                  </div>
                )}
              </div>
            </NavHamburger>
          </div>

          <div className={styles.navToolbarSection}>
            <Link href="/" className={styles.logo}>
              <Image
                src={AppSettings.namePath}
                alt={AppSettings.name}
                style={{ verticalAlign: 'middle' }}
                priority
                width={114}
                height={42}
              />
            </Link>
          </div>

          <div className={styles.navToolbarSection}>
            {isLgi ? (
              <div className={styles.avatar} onClick={() => router.push('/profile')}>
                {userInfo?.first_name?.charAt(0) || 'C'}
              </div>
            ) : (
              <span style={{ cursor: 'pointer' }} onClick={() => router.push('/login')}>
                {t('Login')}
              </span>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.navToolbar}>
          <div className={styles.navToolbarSection}>
            <NavHamburgerDash>
              <></>
            </NavHamburgerDash>
          </div>

          <div className={styles.desc}>{getTitle().desc}</div>

          {/* <div className={styles.navToolbarSection}>
                <div
                  style={{
                    height: "2rem",
                    width: "2rem",
                    textAlign: "center",
                    lineHeight: "2rem",
                    backgroundColor: "var(--app-primary-color)",
                    borderRadius: "2rem",
                  }}
                >
                  {userInfo?.first_name?.charAt(0) || "C"}
                </div>
              </div> */}

          <NavHamburger iNode={<></>}>
            <></>
          </NavHamburger>
        </div>
      )}
    </div>
  );
};
export default MobileHeader;
