import Link from 'next/link';
import styles from './index.module.less';
import { AppSettings, pageMetadataMap, PageType } from '@/shared/app-common';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { fetchArticleResourceData } from '@/lib/service';
import { useEffect, useState } from 'react';
import { HomeArticleModel } from '@/types/home';
import { debounce } from 'lodash';
import Language from '@/components/Common/Language';

const GlobalFooter = ({
  show = true,
  className,
  pageType,
}: {
  show?: boolean;
  className?: string;
  pageType?: PageType;
}) => {
  const { t } = useTranslation('common');
  const router = useRouter();
  const [dataList, setDataList] = useState<HomeArticleModel[]>([]);

  if (!show) {
    return <></>;
  }

  const cls = classNames(styles.footer, className);

  return (
    <footer className={cls}>
      <div className={styles.container}>
        <div className={styles.logoSection}>
          <div className={styles.logo}>
            <h2 style={{ display: 'none' }}>{t('Erasa')}</h2>
            <div className={styles.logoIcon}>
              <Image src={'/images/layouts/erasa.webp'} alt={AppSettings.name} loading="lazy" width={160} height={50} />
            </div>
          </div>

          <p style={{ marginTop: '20px' }}>{t('Secure your content,erase leaks!')}</p>

          <div style={{ marginTop: '20px' }}>
            <Language />
          </div>

          <div className={styles.socialContainer}>
            <p>{t('Follow us')}</p>
            <div className={styles.socialLinks}>
              <Link href="https://twitter.com/Erasaprotect" target="__block">
                <Image src="/images/layouts/x-link-icon.webp" alt="x" width={32} height={32} loading="lazy" />
              </Link>
              <Link href="https://www.facebook.com/profile.php?id=61565526387773" target="__block">
                <Image src="/images/layouts/fb-link-icon.webp" alt="facebook" width={32} height={32} loading="lazy" />
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.linksGrid}>
          <div className={styles.linkColumn}>
            <h3>{t('Product').toLocaleUpperCase()}</h3>
            <ul>
              <li>
                <Link href={`${process.env.NEXT_APP_WEB_HOST}`}>{t('Content Protection')}</Link>
              </li>
              <li>
                <Link href={`${process.env.NEXT_APP_WEB_HOST}/onlyfans-protection`}>
                  {t('OnlyFans Content Protection')}
                </Link>
              </li>
              <li>
                <Link href={`${process.env.NEXT_APP_WEB_HOST}/remove-fake-account`}>
                  {t('Impersonation Account Removal')}
                </Link>
              </li>
              <li>
                <Link href={`${process.env.NEXT_APP_WEB_HOST}/cam-model-protection`}>{t('Cam Model Protection')}</Link>
              </li>
              <li>
                <Link href={`${process.env.NEXT_APP_WEB_HOST}/content-monitoring`}>{t('Content Monitoring')}</Link>
              </li>
              <li>
                <Link href={`${process.env.NEXT_APP_WEB_HOST}/dmca-takedown`}>{t('DMCA Takedown')}</Link>
              </li>
            </ul>
          </div>

          <div className={styles.linkColumn}>
            <h3>{t('Resources').toLocaleUpperCase()}</h3>
            <ul>
              {
                <li>
                  <Link href={`${process.env.NEXT_APP_WEB_HOST}/plan`} target="__block">
                    {t('Pricing')}
                  </Link>
                </li>
              }
              <li>
                <Link href={`${process.env.NEXT_APP_WEB_HOST}/blog`}>{t('blog')}</Link>
              </li>
              <li>
                <Link href={`${process.env.NEXT_APP_WEB_HOST}/dmca-protection-badge`}>{t('DMCA Badge')}</Link>
              </li>
              <li>
                <Link href={`https://t.me/erasaprotect`} target="_blank">
                  {t('Professional Support')}
                </Link>
              </li>
              <li>
                <Link href={`${process.env.NEXT_APP_WEB_HOST}/affiliate`} target="_blank">
                  {t('Affiliate Program')}
                </Link>
              </li>
            </ul>
          </div>

          <div className={styles.linkColumn}>
            <h3>{t('Free Tools').toLocaleUpperCase()}</h3>
            <ul>
              <li>
                <Link href={`${process.env.NEXT_APP_WEB_HOST}/onlyfans-restricted-words-checker`}>
                  {t('OnlyFans Restricted Words Checker')}
                </Link>
              </li>
              <li>
                <Link href={`${process.env.NEXT_APP_WEB_HOST}/onlyfans-caption-generator`}>
                  {t('OnlyFans Caption Generator')}
                </Link>
              </li>
              <li>
                <Link href={`${process.env.NEXT_APP_WEB_HOST}/compare`} target="_blank">
                  {t('Platform comparison')}
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className={styles.bottomSection}>
        <p>All rights reserved. © 2024 Erasa</p>
        <div className={styles.legalLinks}>
          <Link href={`${process.env.NEXT_APP_WEB_HOST}/privacy-policy`}>{t('privacyPolicy')}</Link>
          <Link href={`${process.env.NEXT_APP_WEB_HOST}/terms-us`}>{t('terms of us')}</Link>
        </div>
      </div>
    </footer>
  );
};

export default GlobalFooter;
