import Link from 'next/link';
import classNames from 'classnames';
import { useCallback, useMemo, useRef, useState } from 'react';
import styles from './index.module.less';
import RetroTitle from '@/components/Common/RetroTitle';
import type { MarketplaceNavItem } from '@/components/Layouts';
import { NavItem, MobileHeader } from '@/components/Layouts';
import { AppSettings } from '@/shared/app-common';
import { Button, Dropdown, MenuProps, message } from 'antd';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useUserProvider } from '@/context/UserProvider';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';
// import ChangeLanguage from "@/components/Common/Language";
import { Form, Input, Select, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { feedback } from '@/lib/service';
import OurModal from '@/components/Modal';
import UnloginHeader from '@/components/UnloginHeader/index';
import useScoll from '@/context/UseScrollHeight';

interface GlobalHeaderProps {
  navItems?: MarketplaceNavItem[];
  isMobile?: boolean;
  isStarData?: boolean;
}
type FieldType = {
  type?: string;
  content?: string;
};

const GlobalHeader = ({ navItems, isMobile, isStarData }: GlobalHeaderProps) => {
  const { isLogin, onSignOut, userInfo } = useUserProvider();

  const [form] = Form.useForm();

  const { t } = useTranslation('common');
  const router = useRouter();
  const handleOpenModal = () => {
    setisModalOpen(true);
  };
  const [isModalOpen, setisModalOpen] = useState(false);
  const imageUrl = useRef([]);
  const [confirmLoding, setconfirmLoding] = useState(false);
  const scrollPosition = useScoll();

  const handleFinish = (e: any) => {
    let params = e;
    params.pic_list = imageUrl.current.filter((item) => item);
    setconfirmLoding(true);
    feedback(params).then((_) => {
      form.resetFields();
      setisModalOpen(false);
      setconfirmLoding(false);
      message.success('success');
    });
  };
  const handleUploadChange = (e: any) => {
    imageUrl.current.push(e.file.response?.data);
  };

  const items: MenuProps['items'] = [
    {
      key: '4',
      label: (
        <div style={{ textAlign: 'center' }}>
          <Link href={'/setting'}>{t('Settings')}</Link>
        </div>
      ),
    },
    {
      key: '5',
      label: <span onClick={handleOpenModal}>{t('problem feedback')}</span>,
    },
    {
      key: '6',
      label: (
        <div style={{ textAlign: 'center' }}>
          <Link
            href={'/'}
            onClick={() => {
              onSignOut();
              ga_logEvent(EventName.header_Logout);
            }}
          >
            {t('LogOut')}
          </Link>
        </div>
      ),
    },
  ];

  const getNavLeftSection = useMemo(() => {
    // if (!!isMarketplaceHome) {
    return (
      <div className={styles.navTitleContainer}>
        <div className={styles.catchPhrase}>
          <Link href="/">
            <RetroTitle
              text={
                <div style={{ height: '32px' }}>
                  <img
                    src={AppSettings.namePath}
                    alt={AppSettings.name}
                    style={{ height: '32px', marginLeft: '10px' }}
                  />
                </div>
              }
              fontSize={28}
              showAlert={true}
            />
          </Link>
        </div>
      </div>
    );
  }, []);

  const formatMenuData = useCallback(
    (navItems?: MarketplaceNavItem[]): MarketplaceNavItem[] => {
      return navItems;
    },
    [isMobile],
  );

  const renderNavRightSection = useMemo(() => {
    return formatMenuData(navItems)?.map((item, index) => (
      <NavItem
        item={item}
        key={index}
        className={classNames('', {
          'mobile-left-menu-item': isMobile,
        })}
      />
    ));
  }, [formatMenuData, isMobile, navItems]);

  if (isMobile) {
    return <MobileHeader navItems={navItems}></MobileHeader>;
  }

  const getStyles = (): any => {
    // useScoll
    return {
      position: scrollPosition > 1 ? 'fixed' : 'absolute',
      backgroundColor: computedPathName(router.pathname) || scrollPosition > 10 ? '#ffffff' : 'transparent',
    };
  };

  const computedPathName = (pathname: string) => {
    let flag = false;
    let routerArr = ['/dmca-protection-badge', '/compare', '/content-monitoring', '/dmca-takedown'];
    for (let i = 0; i < routerArr.length; i++) {
      if (pathname.indexOf(routerArr[i]) > -1) {
        flag = true;
        break;
      }
    }

    return flag;
  };
  return (
    <div className={styles.headerContainer} style={getStyles()}>
      {!isMobile && !isLogin && <UnloginHeader></UnloginHeader>}
      <div className={classNames(styles.globalHeaderWarp)}>
        <div className={styles.navSection}>{getNavLeftSection}</div>
        <div className={styles.navSection} style={{ justifyContent: 'center', gap: '1.5rem' }}>
          {renderNavRightSection}
        </div>

        <div className={styles.navSection}>
          {isLogin && router.pathname != '/login' ? (
            <>
              <Button className={styles.login} onClick={() => router.push('/dashboard')}>
                {t('Dashboard')}
              </Button>
              <Dropdown
                menu={{ items }}
                placement="bottomRight"
                autoAdjustOverflow={false}
                overlayClassName={styles.menus}
              >
                {userInfo?.avatar ? (
                  <img
                    className={styles.avatar}
                    src={userInfo?.avatar}
                    alt="erasa"
                    onClick={() => router.push('/dashboard')}
                  />
                ) : (
                  <span className={styles.avatar} onClick={() => router.push('/dashboard')}>
                    {userInfo?.first_name?.charAt(0) || 'E'}
                  </span>
                )}
              </Dropdown>
            </>
          ) : (
            <Button
              className={styles.login}
              onClick={() => {
                router.push('/login');
                ga_logEvent(EventName.header_SignIn);
              }}
            >
              {t('SignIn_SignUp')}
            </Button>
          )}
        </div>
      </div>
      <OurModal isModalOpen={isModalOpen} handleOk={() => {}} handleCancel={() => setisModalOpen(false)} width={500}>
        <div className={styles.p_con}>
          <div>{t('problem feedback')}</div>
          <div></div>
          <div className={styles.p_f_con}>
            <Form
              name="basic"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={handleFinish}
              onFinishFailed={() => {}}
              autoComplete="off"
              layout="vertical"
              form={form}
            >
              <Form.Item<FieldType>
                label={t('Feedback type')}
                name="type"
                rules={[
                  {
                    required: true,
                    message: t('Please change your question type!'),
                  },
                ]}
              >
                <Select placeholder={t('Question type')}>
                  <Select.Option value="Bug/Error">{t('Bug/Error')}</Select.Option>
                  <Select.Option value="Feature Request">{t('Feature Request')}</Select.Option>
                  <Select.Option value="User Experience">{t('User Experience')}</Select.Option>
                  <Select.Option value="Other">{t('Other')}</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item<FieldType>
                label={t('Problem description')}
                name="content"
                rules={[
                  {
                    required: true,
                    message: t('Please input your question description!'),
                  },
                ]}
              >
                <Input.TextArea rows={5} placeholder={t('Question Description')} />
              </Form.Item>
              <Form.Item label={t('image upload')} valuePropName="fileList">
                <Upload
                  action={process.env.NEXT_APP_API_HOST + '/account/upload/image'}
                  onChange={handleUploadChange}
                  maxCount={2}
                  headers={{
                    Authorization: Cookies.get('userToken'),
                  }}
                  listType="picture-card"
                >
                  <button style={{ border: 0, background: 'none' }} type="button">
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>{t('Upload')}</div>
                  </button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <div className={styles.btn_c}>
                  <Button
                    onClick={() => {
                      form.resetFields();
                      setisModalOpen(false);
                    }}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button htmlType="submit" loading={confirmLoding}>
                    {t('Confirm')}
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </OurModal>
    </div>
  );
};

export default GlobalHeader;
